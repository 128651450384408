import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import StatsPage from './pages/StatsPage';
import Auto from './pages/Auto';

const App = () => {
	return (
		<Router>
			<div className="App" >
				<Switch>
					<Route path="/week" component={({...props}) => <StatsPage thisWeek {...props} />} />

					<Route path="/year" component={({ ...props }) => <StatsPage thisYear {...props} />} />

					<Route path="/auto" component={({...props}) => <Auto {...props} />} />

					<Route path="/" component={StatsPage} />
				</Switch>
			</div>
		</Router>
	);
}

export default App;
