import React, { Fragment, useState, useEffect } from 'react';
import StatsPage from './StatsPage';

const Auto = () => {
    const [thisWeek, setThisWeek] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setThisWeek(!thisWeek);
        }, 10000)

        return () => {
            clearTimeout(timeout)
        }
    }, [thisWeek])

    return (
        <Fragment>
            {thisWeek ? <StatsPage thisWeek /> : <StatsPage />}
        </Fragment>
    );
}

export default Auto;
