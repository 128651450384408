import firebase from "firebase/app";
import 'firebase/firestore'
import 'firebase/auth'
// import 'firebase/storage'

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyD6PtzbsKuRDWZeTLU4cGequkGCqMc1vqw",
    authDomain: "shuffleboard-tracker.firebaseapp.com",
    databaseURL: "https://shuffleboard-tracker.firebaseio.com",
    projectId: "shuffleboard-tracker",
    storageBucket: "shuffleboard-tracker.appspot.com",
    messagingSenderId: "156201180892",
    appId: "1:156201180892:web:7ac531626a9520c49c5e19",
    measurementId: "G-92PK0R7Q42"
});

const db = firebaseApp.firestore();
const auth = firebaseApp.auth();
// const storage = firebaseApp.storage();

export { firebase, db, auth };