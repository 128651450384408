import React from 'react';
import {
    EuiTitle,
    EuiPanel,
    EuiFlexGroup,
    EuiFlexItem,
    EuiStat,
} from '@elastic/eui';

import Image from './Image';

const PlayerCard = ({ stats, place }) => {
    // const Stat = ({ title, value }) => <EuiTitle size="s" ><h4>{title}: {value}</h4></EuiTitle>
    const Stat = ({ title, value }) => <EuiStat title={value} description={title} titleSize={place === 1 ? "l" : place <= 3 ? "m" : "s"} />

    const user = stats.user
    const hasPhoto = false && user.photoUrl && user.photoUrl.length > 0

    return (
        <EuiPanel>
            <EuiFlexGroup alignItems="center" >
                <EuiFlexItem grow={1} >
                    <EuiFlexGroup alignItems="center" >
                        <EuiFlexItem  >
                            <EuiTitle size={place <= 3 ? "l" : "m"} ><h2>{place}</h2></EuiTitle>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <Image
                                src={hasPhoto ? user.photoUrl : "https://upload.wikimedia.org/wikipedia/commons/2/24/Missing_avatar.svg"}
                                fallbackSrc="https://upload.wikimedia.org/wikipedia/commons/2/24/Missing_avatar.svg"
                                alt={user.username}
                                style={{
                                    imageOrientation: 'from-image',
                                    objectFit: 'cover',
                                    width: 50,
                                    height: 50,
                                    borderRadius: '50%'
                                }}
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>

                <EuiFlexItem grow={3} >
                    <EuiTitle size={place <= 3 ? "m" : "xs"} ><h3>{user.username}</h3></EuiTitle>
                </EuiFlexItem>

                <EuiFlexItem grow={3} >
                    <Stat title="Seire" value={`${stats.winPercentage} % (${stats.totalVictories} av ${stats.totalGames})`} />
                </EuiFlexItem>

                <EuiFlexItem grow={3} >
                    <Stat title="Gj.snitt poeng" value={stats.averageScore} />
                </EuiFlexItem>

                <EuiFlexItem grow={3} >
                    <Stat title="Poeng totalt" value={stats.totalScore} />
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiPanel>
    );
}

export default PlayerCard;
