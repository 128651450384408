import { useState, useEffect } from 'react';
import confetti from 'canvas-confetti';

import { db } from './firebase'
import { getBeginningOfWeek } from './utils';

const _showConfetti = () => {
    confetti({
        particleCount: 150,
        spread: 180,
        ticks: 1000,
    })
}

const _getUserStats = (users, games, threshold = 5) => {
    const userStats = []

    users.forEach(user => {
        if (user.username && user.username.length > 0) {
            const stats = {
                totalVictories: 0,
                totalScore: 0,
                totalGames: 0,
            }

            games.forEach(game => {
                if (game.team1.includes(user.id)) {
                    stats.totalScore = stats.totalScore + game.player1Score;

                    if (game.player1Score > game.player2Score) {
                        stats.totalVictories = stats.totalVictories + 1;
                    }

                    stats.totalGames = stats.totalGames + 1;
                }

                if (game.team2.includes(user.id)) {
                    stats.totalScore = stats.totalScore + game.player2Score;

                    if (game.player2Score > game.player1Score) {
                        stats.totalVictories = stats.totalVictories + 1;
                    }

                    stats.totalGames = stats.totalGames + 1;
                }
            })

            stats.winPercentage = stats.totalGames <= 0 ? 0 : ((stats.totalVictories / stats.totalGames) * 100).toFixed(0);
            stats.averageScore = stats.totalGames <= 0 ? "0" : (stats.totalScore / stats.totalGames).toFixed(0);
            stats.user = user;

            userStats.push(stats)
        }
    })

    const userStatsMoreThanThreshold = userStats.filter(s => s.totalGames >= threshold);
    const userStatsLessThanThreshold = userStats.filter(s => s.totalGames < threshold);

    const sort = (a, b) => {
        const compare = b.winPercentage - a.winPercentage

        if (compare === 0) return b.totalGames - a.totalGames

        return compare
    }

    userStatsMoreThanThreshold.sort(sort);
    userStatsLessThanThreshold.sort(sort);

    // userStats.push({ user: { id: 1, username: "test" }, totalVictories: 7, totalScore: 0, totalGames: 7, winPercentage: 100, averageScore: 0 })
    // userStats.push({ user: { id: 2, username: "test" }, totalVictories: 7, totalScore: 0, totalGames: 7, winPercentage: 100, averageScore: 0 })
    // userStats.push({ user: { id: 3, username: "test" }, totalVictories: 7, totalScore: 0, totalGames: 7, winPercentage: 100, averageScore: 0 })
    // userStats.push({ user: { id: 4, username: "test" }, totalVictories: 7, totalScore: 0, totalGames: 7, winPercentage: 100, averageScore: 0 })
    // userStats.push({ user: { id: 5, username: "test" }, totalVictories: 7, totalScore: 0, totalGames: 7, winPercentage: 100, averageScore: 0 })
    // userStats.push({ user: { id: 6, username: "test" }, totalVictories: 7, totalScore: 0, totalGames: 7, winPercentage: 100, averageScore: 0 })

    return [...userStatsMoreThanThreshold, ...userStatsLessThanThreshold]
}

const useData = (thisWeek = false, thisYear = false) => {
    const [loadingGames, setLoadingGames] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [error, setError] = useState(null);
    const [games, setGames] = useState(null);
    const [users, setUsers] = useState(null);
    const [firstPlaceUser, setFirstPlaceUser] = useState(null);

    const unsubscribeUsers = db.collection("users").onSnapshot((snap) => {
        const docs = snap.docs

        const _users = docs.map(doc => ({ id: doc.id, ...doc.data() }))

        if (JSON.stringify(_users) !== JSON.stringify(users)) {
            setUsers(_users)
            if (loadingUsers) setLoadingUsers(false)
        }
    });

    let gamesRef = db.collection("games");

    if (thisWeek) {
        const beginningOfWeek = getBeginningOfWeek();

        gamesRef = gamesRef.where("createdAt", ">=", beginningOfWeek);
    } else if (thisYear) {
        const now = new Date();
        const beginningOfYear = new Date(now.getFullYear(), 0, 0);

        gamesRef = gamesRef.where("createdAt", ">=", beginningOfYear);
    }

    const unsubscribeGames = gamesRef.onSnapshot((snap) => {
        const docs = snap.docs

        const _games = docs.map(doc => ({ id: doc.id, ...doc.data() }))

        if (JSON.stringify(_games) !== JSON.stringify(games)) {
            setGames(_games)
            if (loadingGames) setLoadingGames(false)
        }
    });

    const handleData = () => {
        setError(null)
        setLoadingGames(true)
        setLoadingUsers(true)

        return () => {
            unsubscribeUsers()
            unsubscribeGames()
        }
    }

    useEffect(handleData, [])

    const isLoading = loadingGames || loadingUsers

    const threshold = thisWeek || thisYear ? 3 : 5
    const userStats = games && users ? _getUserStats(users, games, threshold) : null

    if (!firstPlaceUser && userStats) setFirstPlaceUser(userStats[0])

    if (userStats && firstPlaceUser && userStats[0].user.id !== firstPlaceUser.user.id) {
        setFirstPlaceUser(userStats[0])
        _showConfetti()
    }

    return [isLoading, error, userStats]
}

export default useData