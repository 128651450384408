const _subtractDays = (date, days) => {
    const res = new Date(date.getTime());

    res.setDate(date.getDate() - days);

    return res;
}

export const getBeginningOfWeek = time => {
    if (!time) time = new Date();
    let week;

    for (let i = 0; i < 7; i++) {
        const lastDay = _subtractDays(time, i);

        if (lastDay.getDay() === 1) {
            week = lastDay;
        }
    }

    const beginningWeek = new Date(week.getFullYear(), week.getMonth(), week.getDate());

    return beginningWeek;
}