import React, { Fragment } from 'react';
import {
    EuiPage,
    EuiPageBody,
    EuiPageContent,
    EuiPageContentBody,
    EuiPageContentHeader,
    EuiTitle,
    EuiImage,
    EuiTextColor,
    EuiFlexGroup,
    EuiFlexItem,
} from '@elastic/eui';
import FlipMove from 'react-flip-move';

import useData from '../helpers/useData'
import PlayerCard from '../components/PlayerCard'

const StatsPage = ({ thisWeek = false, thisYear = false }) => {
    const [isLoading, error, userStats] = useData(thisWeek, thisYear)

    return (
        <Fragment>
            <EuiPage>
                <EuiPageBody>
                    <EuiPageContent>
                        <EuiPageContentHeader>
                            <EuiFlexGroup justifyContent="spaceBetween" alignItems="center" >
                                <EuiFlexItem grow={false} >
                                    <EuiImage url="./images/octaosLogo.png" size={100} alt="octaosLogo" />
                                </EuiFlexItem>

                                <EuiFlexItem component="span" >
                                    <EuiFlexGroup justifyContent="center" >
                                        <EuiFlexItem grow={false} ><EuiImage url="./images/star.svg" alt="star" size={40} /></EuiFlexItem>

                                        <EuiFlexItem grow={false} >
                                            <EuiTitle size="l" >
                                                <h1>
                                                    Shuffleboard Ranking - {' '}
                                                    <EuiTextColor color="secondary" >
                                                        {thisWeek ? "Denne uken" : thisYear ? new Date().getFullYear() : "All time"}
                                                    </EuiTextColor>
                                                </h1>
                                            </EuiTitle>
                                        </EuiFlexItem>

                                        <EuiFlexItem grow={false} ><EuiImage url="./images/star.svg" alt="star" size={40} /></EuiFlexItem>
                                    </EuiFlexGroup>
                                </EuiFlexItem>

                                <EuiFlexItem grow={false} >
                                    <EuiImage url="./images/shuffleboardRankingQR.png" size={100} alt="shuffleboardRankingQR" />
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiPageContentHeader>
                        <EuiPageContentBody>
                            <FlipMove className="grid" duration="400" delay="0" staggerDurationBy="0" staggerDelayBy="300" easing="ease-in-out" >
                                {!isLoading && !error && userStats && userStats.map((stats, index) => (
                                    <div key={stats.user.id} className={index < 3 ? "card-wide" : "card-small"} >
                                        <PlayerCard stats={stats} place={index + 1} />
                                    </div>
                                ))}
                            </FlipMove>
                        </EuiPageContentBody>
                    </EuiPageContent>
                </EuiPageBody>
            </EuiPage>
        </Fragment>
    );
}

export default StatsPage;
