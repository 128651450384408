import React, { useState } from 'react';

const Image = (props) => {
    const [src, setSrc] = useState(props.src);
    const [errored, setErrored] = useState(false);

    const handleError = () => {
        if (!errored) {
            setSrc(props.fallbackSrc)
            setErrored(true)
        }
    }

    const { src: _1, fallbackSrc: _2, style = {}, alt, ...rest } = props;

    return (
        <img
            src={src}
            onError={handleError}
            style={errored ? { ...style, backgroundColor: 'red' } : style}
            alt={alt}
            {...rest}
        />
    )
}

export default Image;